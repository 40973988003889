var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-set-password" }, [
    _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "label" }, [_vm._v("用户名：")]),
        _c(
          "div",
          { staticClass: "inp-box" },
          [
            _c("el-input", {
              staticClass: "inp",
              attrs: {
                type: "text",
                placeholder: "请输入",
                disabled: _vm.roleType == "2"
              },
              model: {
                value: _vm.username,
                callback: function($$v) {
                  _vm.username = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "username"
              }
            })
          ],
          1
        )
      ]),
      _vm.roleType == "2"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "label" }, [_vm._v("原密码：")]),
            _c(
              "div",
              { staticClass: "inp-box" },
              [
                _c("el-input", {
                  staticClass: "inp",
                  attrs: { type: "password", placeholder: "请输入原密码" },
                  model: {
                    value: _vm.originPassword,
                    callback: function($$v) {
                      _vm.originPassword =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "originPassword"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "label" }, [_vm._v("新密码：")]),
        _c(
          "div",
          { staticClass: "inp-box" },
          [
            _c("el-input", {
              staticClass: "inp",
              attrs: { type: "password", placeholder: "请输入新密码" },
              model: {
                value: _vm.newPassword,
                callback: function($$v) {
                  _vm.newPassword = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "newPassword"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "label" }, [_vm._v("确认密码：")]),
        _c(
          "div",
          { staticClass: "inp-box" },
          [
            _c("el-input", {
              staticClass: "inp",
              attrs: { type: "password", placeholder: "请再次输入新密码" },
              model: {
                value: _vm.password,
                callback: function($$v) {
                  _vm.password = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "password"
              }
            })
          ],
          1
        )
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "el-button",
            {
              staticClass: "submit",
              attrs: { type: "primary" },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "el-button",
            {
              staticClass: "back",
              attrs: { type: "text" },
              on: { click: _vm.onBack }
            },
            [_vm._v("返回登录页")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "inp-box txt" }, [
        _vm._v(
          "密码必须最少包含字母、数字、1个大写字母、1个小写字母,长度8-16位"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }